import React from "react";

import "./App.css";

import Grid from "@material-ui/core/Grid";

import { ReactComponent as IconPhone } from "./assets/icons/phone-call.svg";
import { ReactComponent as IconEmail } from "./assets/icons/email.svg";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import linklogo from "./assets/link-logo-new.png";
import hawslogo from "./assets/hawslogo.png";
import dhlogo from "./assets/dh-logo.png";
import hawsgriplogo from "./assets/hawsgrip-logo-cropped.jpg";
import phlogo from "./assets/ph-logo-cropped.jpg";
import welinklogo from "./assets/welink-logo.png";

function App() {
  return (
    <div className="App">
      <div className={"navbar"}></div>

      <header className="header">
        <img src={linklogo} className="logo" alt="logo" />
        <h2>
          Driving digital transformation and increasing efficient connectivity
          between people and process
        </h2>
      </header>
      <div className={"content maxwidth"}>
        <p>
          <b>l1nk</b> is a Philippine registered corporation duly organized and existing since 10th of December 2020 under the laws of the Republic of the Philippines with SEC Registration No. CS202067462; duly compliant with regulatories, we operate as an Information Technology organization.
        </p>
        <p>
          l1nk is driving digital-first engagements between people, products, and services. In l1nk, we believe that it is all about leveraging technology to facilitate seamless interactions and enhance business processes to ensure engagements occur effortlessly, regardless of time or location, to maximize productivity and achieve optimal results.
        </p>
        <p>
          By prioritizing digital-first approaches we continuously improve accessibility, and foster collaboration. Ultimately, the goal is to integrate digital solutions effectively into everyday operations, enabling people and businesses to achieve both short-term outputs and long-term outcomes such as increased agility, innovation, and customer satisfaction. This approach aligns with the modern landscape where connectivity and efficiency are paramount to sustained success.
        </p>

        <p>
          Our flagship brand is haws, Health And Wellness Solutions. More connected brands under l1nk inc. includes: POWERhaws OpenTalks Exclusive, hawsGRIP, weL1NK, Digital Health Kiosk powered by haws.
        </p>

        <p>
          <u>About the flagship brand: haws</u><br/>
          haws is an assistive tele-health platform that conveniently connects patients and healthcare providers. The
          larger mission of haws includes building a digitally savvy Filipino community who embraces technology in
          caring for themselves and the needs of their loved ones wherever they may be.
        </p>

        <p>
          <u>l1nk inc. operates in the following business names and styles:</u><br/>
          l1nk, l1nk inc., weL1NK, haws, Health And Wellness Solutions, haws call, hawsCares, MISSION hawsCares, MHC, Digital Health Kiosk powered by haws, DHK powered by haws, DHK, POWERhaws, POWERhaws OpenTalks Exclusive, PWH, hawsGRIP, haws Guide.Refresh.Inspire.Peers.
        </p>
      </div>

      <div className={"content maxwidth"}>
        <h2>Our Brands</h2>
        <div className={"brands"}>
          <div className={"logo"}>
            <img width={200} src={dhlogo} className="logo" alt="logo"/>
          </div>
          <div className={"logo"}>
            <a href="https://hawsinc.com">
              <img width={200} src={hawslogo} className="logo" alt="logo"/>
            </a>
          </div>
          <div className={"logo"}>
            <img width={200} src={hawsgriplogo} className="logo" alt="logo"/>
          </div>

          <div className={"logo"}>
            <img width={200} src={phlogo} className="logo" alt="logo"/>
          </div>
          <div className={"logo"}>
            <img width={200} src={welinklogo} className="logo" alt="logo"/>
          </div>
        </div>

      </div>

      <div className={"footer"}>
        <div className={"contact__title"}>
          C O N T A C T&nbsp;&nbsp;&nbsp;U S
        </div>
        <div className={"contact__links"}>
          <Grid container spacing={10} justify={"center"}>
            <Grid item xs={12} sm={6}>
              <a href={"mailto:contact@l1nkinc.com"}>
                {/*<img className={'contact__links-icon'} src={iconEmail}/>*/}
                <IconEmail className={"contact__links-icon"} />
              </a>
              <br />
              <br />
              {/*<h3>E-MAIL</h3>*/}
              <a
                className={"contact__links-text"}
                href={"mailto:contact@l1nkinc.com"}
              >
                contact@l1nkinc.com
              </a>
            </Grid>
            <Grid item xs={12} sm={6}>
              <a href={"tel:+639176232846"}>
                {/*<img className={'contact__links-icon'} src={iconPhone}/>*/}
                <IconPhone className={"contact__links-icon"} />
              </a>
              <br />
              <br />
              {/*<h3>TELEPHONE</h3>*/}
              <a className={"contact__links-text"} href={"tel:+639176232846"}>
                +63 9176232846
              </a>
            </Grid>
          </Grid>
        </div>

        <div className={"footer-copyright"}>
          <Grid container spacing={5} justify={"center"}>
            <Grid item xs={8} className={"footer__copyright"}>
              © L1NK INC.
            </Grid>
            <Grid item xs={4} className={"footer__socialmedia"}>
              {/*<a href={"https://www.facebook.com/healthandwellnessolutions/"}><FacebookIcon /></a>*/}
              {/*<a href={""}><InstagramIcon /></a>*/}
              <a href={"https://www.linkedin.com/company/l1nk/"}>
                <LinkedInIcon />
              </a>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default App;
